<template>
  <v-form @submit.prevent="save" ref="form">
    <section id="myAccount" v-if="isEmailVerified">
      <v-card class="user-details" :loading="loading" :disabled="disabled">
        <v-card-title>Your Details</v-card-title>
        <v-card-subtitle
          >{{ getEmail }}
          <br />
          {{ profile.id }}
        </v-card-subtitle>

        <div class="input-row">
          <div class="input-col">
            <v-text-field
              dense
              placeholder="Enter First Name"
              outlined
              label="First Name"
              width="48%"
              v-model="firstname"
              :rules="[rules.required]"
              color="primary"
            ></v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              dense
              placeholder="Enter Last Name"
              outlined
              label="Last Name"
              width="48%"
              v-model="lastname"
              :rules="[rules.required]"
              color="primary"
            ></v-text-field>
          </div>
        </div>

        <!-- enter phonr form -->
        <form @submit.prevent="sendOtpConfirm" id="phoneForm">
          <div class="input-row">
            <div class="input-col">
              <v-text-field
                :rules="[rules.required]"
                dense
                hint="Enter phone number without Zero in starting"
                placeholder="Enter Srilankan Phone No"
                outlined
                form="phoneForm"
                label="Phone No"
                color="primary"
                v-model="phone"
                prefix="+94"
                type="tel"
                :disabled="phoneDisable"
                pattern="[0-9]{9}"
                required
                maxlength="9"
              ></v-text-field>
            </div>
            <div class="input-col">
              <v-chip class="mb-6" color="green white--text" v-if="pvCode == 2"
                >Verified</v-chip
              >
              <v-chip
                class="mb-6"
                color="red white--text"
                v-if="pvCode == 0 || pvCode == 1"
                >Not Verified</v-chip
              >
            </div>
          </div>
          <div class="input-row">
            <div class="input-col" v-if="pvCode == 0 || pvCode == 1">
              <v-btn
                color="primary"
                form="phoneForm"
                class="text--white mb-6"
                type="submit"
                >Get OTP SMS</v-btn
              >
            </div>

            <div class="input-col" v-else>
              <v-btn
                color="primary"
                @click="changeVerifiedPhoneOnClick()"
                class="text--white mb-6"
                >Change Phone</v-btn
              >
            </div>
          </div>
        </form>

        <div class="input-row">
          <div class="input-col">
            <v-text-field
              :rules="[rules.required]"
              dense
              placeholder="Address line 1"
              outlined
              label="Address line 1"
              color="primary"
              v-model="addressline1"
            ></v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              dense
              placeholder="Address line 2"
              outlined
              color="primary"
              label="Address line 2"
              :rules="[rules.required]"
              v-model="addressline2"
            ></v-text-field>
          </div>
        </div>
        <div class="input-row">
          <div class="input-col">
            <v-text-field
              dense
              placeholder="Address line 3"
              outlined
              label="Address line 3"
              color="primary"
              v-model="addressline3"
            ></v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              dense
              placeholder="Address line 4"
              outlined
              color="primary"
              label="Address line 4"
              v-model="addressline4"
            ></v-text-field>
          </div>
        </div>
        <div class="input-row">
          <div class="input-col">
            <v-text-field
              :rules="[rules.required]"
              dense
              placeholder="Enter Postalcode"
              outlined
              label="Postal Code / Zip Code"
              color="primary"
              v-model="postalcode"
              type="number"
            ></v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              :value="country"
              label="Country"
              disabled
              dense
              outlined
            ></v-text-field>
            <!-- <v-text-field
            required
            dense
            placeholder="Country"
            outlined
            label="Country"
            color="primary"
            v-model="country"
            type="number"
            disabled
            value="Srilanka"
          ></v-text-field> -->
          </div>
        </div>
      </v-card>
      <v-card class="user-id-proof" :loading="loading" :disabled="loading">
        <div>
          <v-card-title> Proof of identification</v-card-title>
          <div class="banner dangerB" v-if="poiCode == 1">
            <p>
              Your Proof Of Identification have been rejected, Kindly upload
              again
            </p>
          </div>
          <div class="banner successB" v-if="poiCode == 2">
            <p>Your Proof Of Identification have been approved</p>
          </div>
          <div class="banner alertB" v-if="poiCode == 0">
            <p>You Proof Of Identification have been submited for approval</p>
          </div>
          <span v-if="poiCode != 0 && poiCode != 2">
            <v-autocomplete
              dense
              label="Your POI"
              placeholder="Enter your POI"
              outlined
              color="primary"
              class="mr-2"
              v-model="POI"
              :items="POIS"
              :rules="[poiRules.required]"
            ></v-autocomplete>
            <div class="input-row">
              <v-file-input
                v-if="
                  POI == 'Passport' ||
                  POI == 'Driving License' ||
                  POI == 'National Identity Card'
                "
                v-model="frontfile"
                color="deep-purple accent-4"
                label="Front copy"
                class="mr-2"
                placeholder="Select your files"
                prepend-icon="mdi-paperclip"
                outlined
                dense
                :rules="[poiRules.required]"
              >
              </v-file-input>
            </div>
            <div class="input-row">
              <v-file-input
                v-if="POI == 'National Identity Card'"
                v-model="backfile"
                color="deep-purple accent-4"
                dense
                label="Back Copy"
                placeholder="Select your files"
                prepend-icon="mdi-paperclip"
                outlined
                :rules="[poiRules.required]"
              >
              </v-file-input>
            </div>
          </span>
        </div>
        <v-btn color="#1DBC90" class="white--text mb-5" type="submit">
          SAVE
          <v-icon right dark> mdi-arrow-right-circle </v-icon>
        </v-btn>
      </v-card>
    </section>

    <!--otp dialogue -->
    <v-dialog v-model="otpDialogue" width="320px" persistent>
      <v-card :loading="otpDialogueLAD" :disabled="otpDialogueLAD">
        <v-card-title>
          <span class="headline">One Time Password</span>
        </v-card-title>
        <v-card-text>
          <form id="otpConfirmForm" v-if="!otpSent">
            <p>
              OTP will be sent to +94 {{ phone }} <br />
              wrong number?
              <span
                color="primary"
                text
                @click="changePhone"
                style="cursor: pointer; color: rgb(45, 77, 218)"
                >Change</span
              >
            </p>
            <v-btn color="primary" class="white--text" @click="sendOtpNow()"
              >SEND OTP</v-btn
            >
            <v-btn
              color="red"
              class="white--text ml-2"
              text
              form="otpConfirmForm"
              @click="closeOtpDialogue"
              >Cancel</v-btn
            >
          </form>

          <!-- enter otp -->
          <form @submit.prevent="verifyOtpNow" id="otpForm" class="mt-0" v-else>
            <p>
              OTP have been sent to <br />+94 {{ phone }} <br />
              wrong number?
              <span
                color="primary"
                text
                @click="changePhone"
                style="cursor: pointer; color: rgb(45, 77, 218)"
                >Change</span
              >
            </p>
            <div class="input-row">
              <v-text-field
                :rules="[rules.required]"
                dense
                placeholder="Enter OTP"
                outlined
                color="primary"
                v-model="otp"
                type="number"
                pattern="[0-9]{6}"
                required
                maxlength="6"
              ></v-text-field>
              <div class="input-col">
                <h3 class="grey--text mb-6 ml-2">{{ timeLeft }}</h3>
              </div>
            </div>
            <v-row class="ml-1 mb-2 mt-0">
              <v-btn
                color="primary"
                class="text--white"
                type="submit"
                form="otpForm"
                >VERIFY</v-btn
              >
              <v-btn
                color="#535353"
                class="text--white ml-2"
                text
                @click="resendOtp"
                :disabled="timerOn"
                >Resend</v-btn
              >
            </v-row>
          </form>
          <small v-if="timerOn">You can resend otp after {{ timeLeft }}</small>
          <v-banner
            color="red"
            class="white--text rounded mt-2"
            v-if="otpError"
          >
            {{ otpErrorMessage }}
          </v-banner>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </v-form>
</template>

<script>
import { userService, calculatorService } from "../.././services";
import { mapActions, mapGetters } from "vuex";
import { auth } from "../../firebase";
export default {
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["profile"]),
    getEmail() {
      return auth.currentUser.email;
    },
    isEmailVerified() {
      return auth.currentUser.emailVerified;
    },
  },
  data() {
    return {
      //rules
      rules: {
        required: (value) => !!value || "Required.",
      },

      poiRules: {
        required: (value) => !!value || "Required.",
      },

      /* 
        pvCode 0 = not verified
        pvCode 1 = change
        pvCode 2 = verified
      */

      //otp
      pvCode: 0,
      otpSent: false,
      otpDialogue: false,
      otpDialogueLAD: false,
      otp: null,
      phoneDisable: false,
      otpError: false,
      otpErrorMessage: null,
      timerInterval: null,
      timeLeft: null,
      timer: 90,
      timerOn: false,

      //fields
      firstname: "",
      lastname: "",
      email: "",
      postalcode: "",
      phone: null,
      city: "",
      country: "Srilanka",
      addressline1: "",
      addressline2: "",
      addressline3: "",
      addressline4: "",

      // right
      POI: "",
      frontfile: null,
      backfile: null,
      POIS: ["National Identity Card", "Passport", "Driving License"],
      poiCode: null,

      //form control
      isRequired: false,
      loading: false,
      disabled: false,

      //country
      countries: [
        { name: "Florida", abbr: "FL", id: 1 },
        { name: "Georgia", abbr: "GA", id: 2 },
        { name: "Nebraska", abbr: "NE", id: 3 },
        { name: "California", abbr: "CA", id: 4 },
        { name: "New York", abbr: "NY", id: 5 },
      ],

      //snackbar
      snackbar: false,
      text: "",
      timeout: 2000,
    };
  },

  methods: {
    ...mapActions(["fetchProfile"]),
    ...mapActions(["verifyOtp"]),
    ...mapActions(["sendOtp"]),
    async init() {
      await this.fetchProfile();
      if (this.profile.firstname) this.firstname = this.profile.firstname;
      if (this.profile.lastname) this.lastname = this.profile.lastname;
      if (this.profile.postalcode) this.postalcode = this.profile.postalcode;
      if (this.profile.addressline1)
        this.addressline1 = this.profile.addressline1;
      if (this.profile.addressline2)
        this.addressline2 = this.profile.addressline2;
      if (this.profile.addressline3)
        this.addressline3 = this.profile.addressline3;
      if (this.profile.addressline4)
        this.addressline4 = this.profile.addressline4;
      if (this.profile.country) this.country = this.profile.country;
      if (this.profile.poiStatus) this.poiCode = this.profile.poiStatus.code;

      //initiate phone status
      if (this.profile.phone) {
        let number = null;
        let verified = false;
        if (this.profile.phone.number) number = this.profile.phone.number;
        if (this.profile.phone.verified) verified = this.profile.phone.verified;
        else this.pvCode = 0;

        if (number != null && verified) {
          this.phone = number;
          this.pvCode = 2;
          this.phoneDisable = true;
        } else {
          this.phone = number;
          this.pvCode = 0;
        }

        this.email = auth.currentUser.email;

        const response = await calculatorService.getCalculationData();
        this.countries = response.countries;
      }
    },

    async logout() {
      await auth.signOut();
      this.$router.push("/");
    },
    refresh() {
      this.$router.go(this.$router.currentRoute);
    },

    //change verified phone
    async changeVerifiedPhoneOnClick() {
      console.log("changeVerifiedPhoneOnClick");
      this.loading = true;
      this.disabled = true;
      await userService.changePhone();
      await this.init();
      this.phoneDisable = false;
      this.loading = false;
      this.disabled = false;
    },

    //otp
    sendOtpConfirm() {
      console.log("Send OTP Confirm");
      this.otpSent = false;
      this.otpError = false;
      this.phoneDisable = true;
      this.otpDialogue = true;
    },
    async sendOtpNow() {
      // this.otpSent = false;
      console.log("send otp now");
      this.otpError = false;
      // this.phoneDisable = true;
      this.otpDialogueLAD = true;

      const phoneNumber = parseInt(this.phone.toString());
      const response = await this.sendOtp(phoneNumber);
      console.log(response);

      if (response.success != false) {
        this.otpSent = true;
        this.startTimer();
      } else {
        if (response.code == 0) {
          this.otpError = true;
          this.otpErrorMessage = "Unable to send OTP to +94 " + this.phone;
        } else if (response.code == 1 || response.code == 2) {
          this.otpSent = true;
        }
      }
      this.otpDialogueLAD = false;
    },
    async verifyOtpNow() {
      this.otpDialogueLAD = true;
      const response = await this.verifyOtp(this.otp);
      if (response.verified) {
        this.otpDialogue = false;
        this.otpSent = false;
        this.pvCode = 2;
        this.stopTimer();
      } else {
        this.otpError = true;
        if (response.msg == "OTP Expired") {
          console.log("OTP Expired", response.msg);
          this.otpErrorMessage = "OTP Expired";
        } else {
          console.log("OTP Invalid", response.msg);
          this.otpErrorMessage = "Enter correct OTP";
        }
      }
      this.otpDialogueLAD = false;
    },
    async resendOtp() {
      this.otpError = false;
      this.stopTimer();
      this.sendOtpNow();
      console.log("resend otp");
      this.startTimer();
    },
    async changePhone() {
      this.stopTimer();
      this.otpSent = false;
      this.phoneDisable = false;
      this.otpDialogue = false;
      console.log("Change phone number");
    },
    startTimer() {
      this.timerOn = true;
      this.timer = 90;
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) this.timer--;
        else {
          this.timerOn = false;
          clearInterval(this.myInterval);
        }

        let minutes = parseInt(this.timer / 60, 10);
        let seconds = parseInt(this.timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timeLeft = minutes + ":" + seconds;
        console.log(this.timeLeft);
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
      console.log("timer stopped");
    },
    closeOtpDialogue() {
      this.stopTimer();
      this.otpSent = false;
      this.phoneDisable = false;
      this.otpDialogue = false;
    },

    //save form
    async save() {
      console.log();
      console.log("Save function called");
      if (this.$refs.form.validate() == false) return;
      else {
        console.log("Form is valid");
        this.loading = true;
        this.disabled = true;

        const profile = {
          firstname: this.firstname,
          lastname: this.lastname,
          postalcode: this.postalcode,
          addressline1: this.addressline1,
          addressline2: this.addressline2,
          addressline3: this.addressline3,
          addressline4: this.addressline4,
          country: this.country,
          poi: this.POI,
        };

        await userService.updateProfile(profile, this.frontfile, this.backfile);
        this.frontfile = null;
        this.backfile = null;
        await this.fetchProfile();
        await this.init();
        this.showSnackbar("Profile updated successfully");
        this.loading = false;
        this.disabled = false;
      }
    },

    //snackbar
    async showSnackbar(text) {
      console.log("show snackbar");
      this.snackbar = true;
      this.text = text;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 90%;
  border-radius: 7px;
  padding: 10px 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.banner p {
  padding: 0px !important;
  margin: 0px !important;
}
.dangerB {
  background-color: #ffd6d6;
  border: 1px solid rgb(187, 19, 19);
  color: rgb(78, 0, 0);
}
.successB {
  background-color: #ceffd6;
  border: 1px solid rgb(0, 100, 17);
  color: rgb(0, 78, 7);
}
.alertB {
  background-color: #fffebf;
  border: 1px solid rgb(176, 187, 19);
  color: rgb(78, 73, 0);
}
a {
  text-decoration: none;
}
#myAccount {
  display: flex;
  justify-content: space-around;
  height: 100%;
  min-height: 100vh;
}
.v-card {
  padding: 0px 1rem;
  border-radius: 10px;
  height: 100%;
}

.input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-col {
  width: 48%;
}
.user-details {
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-id-proof {
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .v-btn {
    width: 100%;
    font-size: 1.1rem;
  }
  .row-1 {
    width: 50%;
    margin-left: 20px;
  }
}
//   Media Querry
@media (max-width: 780px) {
  #myAccount {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    // margin-top: 10vh;
    // margin-left: 10vw;
  }
  .v-card {
    width: 97%;
    margin: 0px 0px 10px 0px !important;
    padding: 0px 1rem;
    border-radius: 10px;
  }
  .user-details {
    width: 100%;
    margin: 40px;
  }

  .user-id-proof {
    width: 100%;
    margin-block: 40px;
  }
  .v-card {
    padding: 20px;
  }
}
</style>
